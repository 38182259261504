@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');

p {
  font-family: 'poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'poppins', sans-serif;
  font-weight: bold;
}

button {
  font-family: 'arim', sans-serif;
}

:root {
  --primary-color: #294F71;
  --secondary-color: #F69133;
}

html {
  overflow-x: hidden;
}

.bold-p {
  margin: 0;
  font-size: 22px !important;
  font-weight: bold;
  color: var(--primary-color) !important;
}

.sign-button {
  color: var(--primary-color) !important;
}

body {
  overflow-x: hidden;
}

.py-6 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.py-7 {
  padding-top: 80px;
  padding-bottom: 80px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  color: #294F71;
}

.hidden-text {
  position: absolute;
  opacity: 0;
  bottom: 0;
  top: 0;
  z-index: -2;
}

h1 {
  font-weight: bolder;
}

.replaceStrong {
  font-weight: bold;
}

i {
  color: #F69133;
}

li {
  list-style: none;
}

.first-button {
  padding: 11px 30px 11px 30px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  font-family: 'poppins', sans-serif;
}

.second-button {
  padding: 10px 27px 10px 27px;
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid #7a7a7a;
  font-family: 'poppins';
  font-weight: bold;
}


.section-header h2 {
  font-size: 24px;
  color: var(--secondary-color);
}

.section-header h3 {
  font-size: 200%;
}


.home-head {
  background-image: url("/src/img/new-head.avif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.home-head-overlay {
  background-color: rgba(4, 38, 56, 0.7);
}

.head-bg {
  background-image: url("/src/img/small-header.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.head-overlay {
  background-color: rgba(4, 38, 56, 0.8);
}

.course-overlay .duration {
  padding: 150px 0;
}

.header-text h1 {
  line-height: 4rem;
  font-size: 45px;
  font-weight: bold;
  color: white;
}

.header-text span {
  color: rgba(246, 145, 51, 1);
  padding: 2px;
}

.header-text p {
  font-size: 20px;
  letter-spacing: 1px;
}

/* Navbar Styling */
.navbar {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.navhome {
  background-color: transparent;
}

.navhome-nav .navhome-link {
  color: #fff;
  font-weight: bold;
}

.nav-home-i i {
  color: #fff;
}

.navbar-nav .active {
  color: #F69133 !important;
}

.nav-item {
  font-weight: 500;
  color: #000 !important;
}

.user-drop .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  /* Removes any box shadow */
  color: var(--primary-color) !important;
  font-weight: 600;
}

.user-drop .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  /* Removes any box shadow */
  color: var(--primary-color) !important;
  font-weight: 600;
}

.home-drop .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  /* Removes any box shadow */
  color: #fff !important;
  font-weight: 600;
}

/* Navbar styling */

/* Home Card */
.hover-col {
  transition: width 0.9s ease;
  height: 251px;
}

.hover-col .card {
  background-image: url("/src/img/our-library.jpg");
  height: 251px;
}

.hover-col .card-body {
  background-color: rgba(4, 38, 56, 0.8);
  color: white;
  height: 251px;
}

.student-img img {
  width: 100px;
  height: 100px;
}

.list-gap {
  line-height: 3rem;
  background-image: url("/src/img/our-library.jpg");
  padding: 0;
  height: 260px;
}

.list-gap-overlay {
  background-color: rgba(4, 38, 56, 0.8);
  height: 260px;
  padding: 0;
  color: white;
}

.slider-content {
  padding: 0 50px;
}

h1 span {
  color: #F69133;
}

.home-course-card h6 {
  color: #F69133;
}

.home-course-card:hover {
  background-color: #294F71;
  color: white;
}

.home-teacher-card h6 {
  color: #F69133;
}

.home-teacher-card:hover {
  background-color: #294F71;
  color: white;
}

.mission-card {
  border: 1px solid #F69133;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.vision-card {
  border: 1px solid #294F71;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

/* Footer styles */

.site-footer {
  /* background-image: url('/src/img/admin-nav-bg.avif'); */
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-link a {
  color: white;
  text-decoration: none;
  line-height: 2rem;
}

.footer-text {
  font-size: 14px;
}

.footer-text h2 {
  font-size: 14px;
  color: white;
}

.footer-border {
  height: 0.1px;
  background-color: rgba(225, 225, 225, 0.4);

}

.footer-develop h2 {
  font-size: 16px;
  color: white;
  text-align: center;
  margin-top: 15px;
}

.footer-develop a {
  text-decoration: none;
}


/* New Footer */


* {
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

.pg-footer {
  font-family: 'Roboto', sans-serif;
}


.footer {
  background-color: #004658;
  color: #fff;
  margin-bottom: -24px;
}

.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}

.footer-wave-path {
  fill: #fffff2;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  /* max-width: 1230px; */
  padding: 40px 15px 300px !important;
  position: relative;
}

.footer-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: #fff;
}

.footer-content-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-logo-link {
  display: inline-block;
}

.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: #fffff2;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}

.footer-menu-list li {
  margin-top: 5px;
}

.footer-call-to-action-description {
  color: #fffff2;
  margin-top: 10px;
  margin-bottom: 20px;
}

.footer-call-to-action-button:hover {
  background-color: #fffff2;
  color: #00bef0;
}

.button:last-of-type {
  margin-right: 0;
}

.footer-call-to-action-button {
  background-color: #027b9a;
  border-radius: 21px;
  color: #fffff2;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .2s;
  cursor: pointer;
  position: relative;
}

.footer-call-to-action {
  margin-top: 30px;
}

.footer-call-to-action-title {
  color: #fffff2;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
}

.footer-call-to-action-link-wrapper a {
  color: #fff;
  text-decoration: none;
}





.footer-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 0;
  width: 236px;
}

.footer-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 236px;
}

.footer-social-amoeba-path {
  fill: #027b9a;
}

.footer-social-link.linkedin {
  height: 26px;
  left: 3px;
  top: 11px;
  width: 26px;
}

.footer-social-link {
  display: block;
  padding: 10px;
  position: absolute;
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.footer-social-icon-svg {
  display: block;
  width: 25px;
  height: 25px;
}

.footer-social-icon-path {
  fill: #fffff2;
  transition: fill .2s;
}

.footer-social-link.twitter {
  height: 28px;
  left: 62px;
  top: 3px;
  width: 28px;
}

.footer-social-link.youtube {
  height: 24px;
  left: 123px;
  top: 12px;
  width: 24px;
}

.footer-social-link.github {
  height: 34px;
  left: 172px;
  top: 7px;
  width: 34px;
}

.footer-copyright {
  background-color: #027b9a;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}



.footer-copyright-link {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-copyright-link:hover {
  color: var(--secondary-color);
}





/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px) {

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 700px !important;
    position: relative;
  }
}

@media (min-width:480px) and (max-width:599px) {

  /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width:600px) and (max-width: 800px) {

  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width:801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

}

@media (min-width:1025px) {
  /* big landscape tablets, laptops, and desktops */

}

@media (min-width:1281px) {
  /* hi-res laptops and desktops */

}




@media (min-width: 760px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
  }

  .footer-wave-svg {
    height: 50px;
  }

  .footer-content-column {
    width: 24.99%;
  }
}

@media (min-width: 568px) {
  /* .footer-content-column {
      width: 49.99%;
  } */
}

/* New Footer */



.accordion-item h2 button {
  background-color: #fff;
  color: #F69133;
  font-weight: 700;
}

.first-one {
  background-color: #fff;
  color: #F69133;
  font-weight: 700;
  box-shadow: 2px 2px 15px rgba(204, 204, 204, 01);
}

.profile-position {
  position: relative;
}

.three-cards .card1,
.three-cards .card2,
.three-cards .card3 {
  height: 100% !important;
}

@media(max-width:600px) {

  .three-cards .card-content {
    padding: 0 10px;
  }
}



.profile-set {
  position: absolute;
  top: 10%;
  right: 1%;
}

.profile-set h3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #294F71;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  color: white;
}

/* 294F71 */
/* F69133 */

.card1 {
  background-color: #F69133;
}

@media(max-width:600px) {
  .three-cards {
    margin-bottom: 20px;
  }
}

.card1:hover {
  transform: translateY(-8%);
  transition: 0.3s ease;
}

.card-content {
  padding: 9px 35px;
  background-color: #F69133;
  border: none;
  justify-content: between;
  align-items: top;
}

.card-content i {
  color: #fff;
  font-size: 30px;
  margin-right: 20px;
}

.card-content h5 {
  color: #fff;
}

.card-content p {
  color: #fff;
  font-size: 18px;
}

.partners img {
  width: auto;
  height: 95px;
}

.partners img:hover {
  filter: grayscale(1);
}

/* Courses category style */

.course-cat {
  position: relative;
  z-index: -1;
}

/* .course-cat img:hover{
  animation: zoom ease-in;
} */

.cat-title {
  position: absolute;
  bottom: 3px;
  right: 3px;
  z-index: 5;
  background-color: #fff;
  padding: 3px 29px 0 29px;
  align-items: center !important;
}

.cat-title h6 {
  font-size: 15px;
  font-weight: 500;
}

.cat-title p {
  color: #F69133;
}

/* Courses Cards style */
.courses {
  border: none;
  box-shadow: 5px 5px 10px 0 rgba(204, 204, 204, 0.5);
  border-radius: 15px;
  position: relative;

}

.courses:hover {
  transform: translateY(-2%);
  transition: 0.3s;
}

.course-title hr {
  width: 80%;
  transform: rotateX('40%') !important;
  color: #a3a3a3;
}

.card-bottom {
  padding-top: 10px;
  padding-bottom: 10px;
}

.course-content {
  position: relative;
}


.course-content img {
  z-index: -1;
  height: 120%;
  border-radius: 15px 15px;
}

.course-content span {
  background-color: #F69133;
  padding: 4px;
  margin-left: -17px;
  margin-right: -17px;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
}

.student {
  position: absolute;
  z-index: 1;
  bottom: 5%;
  left: 6%;
}

.lessons {
  position: absolute;
  z-index: 1;
  bottom: 5%;
  right: 6%;

}

.card-bottom span i {
  color: #fff;
}

.join .first-button {
  padding: 7px 14px 7px 14px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 9px;
  font-family: 'poppins', sans-serif;
}

.join .second-button {
  padding: 7px 14px 7px 14px;
  margin-right: 8px;
  background-color: transparent;
  color: var(--secondary-color);
  border: 1px solid #7a7a7a;
  font-family: 'poppins';
  font-weight: bold;
}

/* About us style */
.about-card .fw-bold {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
}

.empty-col {
  background-image: url('/src/img/about-us.avif');
  background-size: cover;
  background-repeat: no-repeat;

}

.about-col {
  background-image: url('/src/img/about-us.avif');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
}

.overlay {
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(4, 38, 56, 0.9);
}

.about .content {
  padding: 80px;
}

@media (max-width:768px) {
  .about .content {
    padding: 20px;
  }
}


.overview .img-meta {
  position: relative;
}




/* Courses Detail page style */

.courses-bg {
  background-image: url('/src/img/course-page-header.avif');
  background-repeat: no-repeat;
  background-size: cover;
}


.course-data .listing .list-icon,
.user-course-detail-icon {
  font-size: 20px;
  color: var(--secondary-color)
}

.listing li {
  font-size: 20px;
}

.listing {
  box-shadow: 5px 5px 20px rgba(204, 204, 204, 0.5);
  margin-right: 20px;
  padding: 20px;
}

@media (max-width:768px) {
  .listing {
    margin-right: 0;
    padding: 0 20px;
  }
}

.learning-outcomes li:hover {
  transform: translateX(4%);
  transition: 0.2s ease-out;
}


.enroll-card {
  padding: 15px;
  background: rgb(78, 76, 241);
  background: linear-gradient(90deg, rgba(78, 76, 241, 1) 59%, rgba(0, 212, 255, 1) 100%);
  margin-bottom: 15px;
  border-radius: 15px;
  border: none;
}

.enroll-content img {
  width: auto;
  height: 200px;
}

.enroll-content h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 180%;
}

.enroll-content p {
  color: #fff;
  font-weight: 500;
}

.enroll-content button {
  padding: 5px 19px;
  background-color: #F69133;
  border-radius: 25px;
  border: none;
  color: #fff;
  font-weight: 500;
}

.c1 img,
.c2 img,
.c3 img {
  animation: float 5s ease-in-out infinite;
}

.wiat-img {
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

/* Schools page style */

.school-card {
  border-radius: 20px;
  border: none;
  box-shadow: 2px 2px 15px rgba(204, 204, 204, 0.5);
}

.school-img img {
  width: auto;
  height: 180px;
  border-radius: 20px 20px 0 0;
}

.school-content {
  padding: 10px;
  margin-top: 20px;
}

.school-bottom {
  margin-top: 10%;
  padding: 5px;
}

.school-bottom span {
  background-color: #F69133;
  border-radius: 25px;
  padding: 5px 9px;
  color: #fff;
  font-weight: 500;
}

/* School Details page */

.school-detail-card {
  padding: 15px;
  border: none;
  /* box-shadow: 2px 2px -7px rgba(243, 243, 243, 0.9); */
  box-shadow: 2px 2px 35px rgba(204, 204, 204, 0.8);
  height: 100%;
}

.contact-meta .school-icon {
  font-size: 22px;
  color: #ccc;
}

.contact-meta p {
  font-size: 15px;
  font-weight: 500;
}

.school-description {
  box-shadow: 2px 2px 35px rgba(204, 204, 204, 0.8);
  border-radius: 10px;
  margin-bottom: 15px;
}


/* Footer styles */
.site-footer {
  background-color: #294F71;
}

.about-content p {
  font-weight: 500;
}

.stats-col .card-body {
  padding-top: 30px;
  padding-bottom: 30px;
}

.card-body:hover {
  text-align: center;
}

.about-content .stat-icon {
  font-size: 35px;
}

.counter {
  font-size: 30px;
  font-weight: 700;
}

.counter::after {
  content: "+";
}

.footer-social {
  font-size: 22px;
}

.card-1 {
  background-color: #DCF1FD;

  box-shadow: 2px 2px 5px rgba(204, 204, 204, 1);
  border-radius: 8px;
}

.card-2 {
  background-color: var(--secondary-color);
  color: #fff;
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 1);
  border-radius: 8px;

}

.card-3 {
  background-color: #E9FBD5;

  box-shadow: 2px 2px 5px rgba(204, 204, 204, 1);
  border-radius: 8px;
  margin-top: -70px;
}

.card-4 {
  background-color: #294F71;
  color: #fff;
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 1);
  border-radius: 8px;
  margin-top: -20px;
}

.accordion-header .accordion-button {
  color: #F69133 !important;
  font-size: 18px;
}

.accord-section .accordion-item {
  margin-bottom: 15px;
}

/* Mission Vision Style */

/* .values {
  background-image: url('/src/img/mission-vision-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
} */

.values .company-card {
  padding: 25px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 1);
}

.pseudo {
  position: relative;
  text-align: center;
}

.pseudo .card {
  height: 100%;
}

.pseudo i {
  font-size: 30px;
}

.pseudo .vision-icon {
  font-size: 30px;
  color: var(--secondary-color);
}

.pseudo .values-icon {
  font-size: 30px;
  color: var(--secondary-color);
}

.pseudo p {
  font-weight: 600;
}

.pseudo .img1 {
  position: absolute;
  top: -12px;
  left: 12px;
  height: 40px;
}

.pseudo .img2 {
  position: absolute;
  bottom: -12px;
  right: 12px;
  height: 40px;
}

.teacher-img {
  height: 130px;
  border-radius: 100px;
}

.inst-info .inst-icon {
  font-size: 16px;
}

.pdf-card {
  border: none;
}

.pdf-card:hover {
  box-shadow: 2px 2px 45px rgba(204, 204, 204, 0.6);
}

.pdf-content {
  padding: 15px;
}


/* Admin Dashboard Styles */

.admin-nav {
  background-color: #204162 !important;
}

.user-meta img {
  height: 80px;
  border-radius: 100px;
}

.dash-card {
  background-color: #fff !important;
  box-shadow: 2px 2px 45px rgba(204, 204, 204, 0.6);
  padding: 10px 9px 10px 9px;
  text-align: center;
  border-radius: 20px;
}

.dash-card h4 {
  font-size: 12px;
  color: #8e8e8e;
}

.dash-card p {
  font-size: 35px;
  font-weight: 600;
}

.dash-card .dash-icons {
  font-size: 45px;
}

.bg-admin {
  background-image: url('/src/img/admin-nav-bg.avif');
  background-repeat: no-repeat;
  background-size: cover;
}

.dashboard-nav-items {
  font-weight: 500;
}

.dashboard-nav-items:hover {
  transform: translateX(3%);
  transition: 0.3s;
}

.ic1 {
  color: #F69133;
}

.ic2 {
  color: #2195ED;
}

.ic3 {
  color: #009788;
}

.ic4 {
  color: #E71F63;
}

.list-class ul li {
  list-style: initial;
  color: #fff;
  list-style-type: square;
}

.schoo-search {
  height: 45px;
  border: 1px solid var(--secondary-color);
  border-radius: 25px;
}

.find input:hover {
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 0.9);
}

.find input:focus {
  border: 1px solid var(--secondary-color);
}

.find select {
  height: 45px;
  border: 1px solid var(--secondary-color);
  border-radius: 25px;
  cursor: pointer;
}

.find select:hover {
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 0.9);
}

.find select:focus {
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 0.9);
  border: 1px solid var(--secondary-color);
}

.signinform form {
  line-height: 2;

}

.signinform input {
  height: 50px;
  /* margin-bottom: 30px; */
  border: 1px solid var(--secondary-color);
  border-radius: 25px;
}

.signinform input:hover {
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 0.9);
}

.signinform input:focus {
  border: 1px solid var(--secondary-color);
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 0.9);
}

.signinform .signin-submit {
  padding: 7px 35px;
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 500;
  border: none;
  border-radius: 25px;
}

.signinform .signin-submit:hover {
  background-color: var(--secondary-color);
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
  transition: 0.3s;
}

.singup-form form {
  line-height: 2;
}

.signup-form .register {
  height: 50px;
  border-radius: 25px;
  border: 1px solid var(--secondary-color);
}

.signup-form .register:hover {
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
}

.signup-form .register:focus {
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
}

.register-button {
  margin-top: 10px;
  padding: 7px 35px;
  border-radius: 25px;
  border: none;
  color: #fff;
  font-weight: 500;
  background-color: var(--primary-color);
}

.register-button:hover {
  background-color: var(--secondary-color);
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
  transition: 0.3s;
}


.user-profile {
  background-color: var(--secondary-color) !important;
}

.user-side-course-img {
  height: 220px !important;
  width: 100%;
}

.call-to-action {
  background: linear-gradient(90deg, rgba(78, 76, 241, 0.7) 59%, rgba(0, 212, 255, 0.7) 100%), url('/src/img/course-enrollment.avif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* height: 350px; */
  width: 100%;
  padding: 0;
  border-radius: 20px;
  color: #fff;
}

.cta-overlay {
  background: linear-gradient(90deg, rgba(78, 76, 241, 0.7) 59%, rgba(0, 212, 255, 0.7) 100%);
  /* height: 350px; */
  width: 100%;
  padding: 0;
  border-radius: 20px;
}

.update-pass input {
  height: 45px;
  border: 1px solid var(--secondary-color);
  border-radius: 25px;
}

.update-pass input:hover {
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
}

.update-pass input:focus {
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
  border: 1px solid var(--secondary-color);
}

.update-pass .register-button {
  margin-top: 10px;
  padding: 7px 35px;
  border-radius: 25px;
  border: none;
  color: #fff;
  font-weight: 500;
  background-color: var(--primary-color);
}

.register-button:hover {
  background-color: var(--secondary-color);
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
  transition: 0.3s;
}

.signinform .forget-pass {
  background-color: transparent;
  border: none;
  color: #2195ED;
  text-decoration: underline;
  margin-left: -9px;
  font-size: 14px;
}

.wait {
  background-image: url('/src/img/wait-bg.avif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  /* height: 300px; */
  width: 100%;
}

.wait img {
  height: 250px;
  animation: float 5s ease-in-out infinite;
}

.wait-overlay {
  position: absolute;
  background-color: rgba(225, 225, 225, 0.9);
  /* height: 300px; */
  width: 100%;
}

.bshadow:hover {
  box-shadow: 2px 2px 10px rgba(204, 204, 204, 0.9);
}

.expert-img {
  height: 250px
}

@media (max-width:768px) {
  .expert-img {
    height: 300px
  }
}

.about-1 {
  margin-bottom: 15px;
}

@media (max-width:768px) {
  .about-1 {
    margin-bottom: 0;
  }

  .card-2 {
    margin-bottom: 45px;
  }
}

.teachr-img {
  height: 250px;
}

@media (max-width:768px) {
  .teachr-img {
    height: 320px;
  }
}

.inst-profile {
  display: flex;
}

.inst-info {
  margin: 0 20px;
}

@media (max-width:768px) {
  .inst-profile {
    display: inline;
  }

  .inst-info {
    margin: 0;
    margin-top: 10px;
  }
}

.schol-img {
  height: 400px;
}

@media (max-width:768px) {
  .schol-img {
    height: 250px;
  }
}

.forgot-password {
  margin-top: -30px;
}

@media (max-width:768px) {
  .forgot-password {
    margin-top: 0;
  }
}

.teacher-social a {
  background-color: var(--primary-color);
}

.teacher-social a:hover {
  background-color: var(--primary-color);
}

.teacher-social i {
  color: white;
}

.teacehr-data p {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.teacehr-data small {
  color: var(--secondary-color);
}

.social a {
  font-size: 17px;
  margin-left: 20px;
}

.phoneInput {
  border-radius: 25px;
  border: 1px solid var(--secondary-color);
  padding: 0 20px;
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
  border: 0px !important;
}

.phonInput {
  border: 1px solid #e7e4e4;
  border-radius: 5px;
}

/* User Profile Page tabs */

.industries li {
  margin-right: 10px;
}

.industries {
  border-bottom: none !important;
}

/* User Profile Page tabs */

.profile-page-btns {
  font-family: 'poppins', sans-serif;
  padding: 12px 20px;
  width: 90%;
  margin-bottom: 5%;
  background-color: transparent;
  border: 1px solid var(--secondary-color);
}

.profile-page-btns:hover {
  box-shadow: 4px 4px 8px 2px rgba(204, 204, 204, 0.8);
  transition: 0.33s ease-in;
}

.custom-tabs {
  padding: 20px;
  box-shadow: 2px 2px 5px 0 rgba(204, 204, 204, 0.5);
  border-radius: 25px;
}

.custom-tabs .nav-link {
  border: 1px solid var(--primary-color) !important;
  color: var(--secondary-color);
  font-family: 'poppins', sans-serif;
  width: 95% !important;
  border-radius: 0 25px;
}

.custom-tabs .nav-link.active {
  background-color: var(--secondary-color);
  color: #fff;
  border: none !important;
}

.custom-tabs .nav-link.active:hover {
  border: none !important;
}

@media (min-width:1000px) {
  .footerMidCol {
    width: 50%;
  }
}

.footer input::placeholder {
  color: white !important;
}

.footer-icon i {
  color: white;
  font-size: 22px;
}


.new-add-course-form input {
  height: 50px;
  border: 1px solid #7a7a7a;
  border-radius: 0 !important;
  box-shadow: 2px 2px 4px rgba(204, 204, 204, 0.5);
}

.new-add-course-form textarea {
  border: 1px solid #7a7a7a;
  border-radius: 0 !important;
  box-shadow: 2px 2px 4px rgba(204, 204, 204, 0.5);
}

.new-add-course-form select {
  height: 50px;
  border: 1px solid #7a7a7a;
  border-radius: 0 !important;
  box-shadow: 2px 2px 4px rgba(204, 204, 204, 0.5);
}

.new-add-course-form input:focus {
  outline: none !important;
  box-shadow: none;
}

.new-add-course-form textarea:focus {
  outline: none !important;
  box-shadow: none;
}

.new-add-course-form select:focus {
  outline: none !important;
  box-shadow: none;
}

.form-accord {
  padding: 20px;
  background-color: transparent;
}

.form-accord:hover {
  box-shadow: 2px 2px 8px rgba(204, 204, 204, 0.7);
  transition: 0.3s ease-in;

}

.form-accord:focus {
  outline: none;
  background-color: transparent !important;
}

.create-account-cta {
  color: var(--primary-color) !important;
}

.lessons-list h6 {
  font-size: 120%;
  font-family: 'poppins', sans-serif;
  font-weight: 450;
}